import React, { useState, useEffect } from "react";
import {
  // Rating,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  Button,
  TextField,
  Typography,
  Box,
  // Chip,
  // Tooltip,
  IconButton,
  InputBase,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { addItemToCart } from "../../../redux/Slices/Cart/index";
import { openCartModal } from "../../../redux/Slices/CartModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "./ProductInformation.css";
import ProductDetailReviews from "./ProductDetailReviews";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

const ProductInformation = ({ data, reviewData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const token = useSelector((state) => state.authSlice.token);

  const selectedVariation = data?.price_variations?.[0] || null;
  const [quantity, setQuantity] = useState(1);

  const handleQuantityChange = (event) => {
    const newQuantity = Math.max(1, parseInt(event.target.value, 10));
    setQuantity(newQuantity);
  };

  const handleAddToCart = (e) => {
    e.stopPropagation();
    if (!selectedVariation) {
      toast.error(t("Please select a variation"));
      return;
    }

    const productToAdd = {
      productId: data.id,
      sku: data.sku,
      name: data.name_en,
      price: selectedVariation?.price,
      quantity,
      imageUrl: data.images[0] || "",
      scale: data.price_variations[0]?.scale
        ? data.price_variations[0]?.scale
        : "small",
      // color: selectedColor,
    };

    dispatch(
      addItemToCart({
        ...productToAdd,
        isLoggedIn,
        token,
        navigate
      })
    );
    dispatch(openCartModal());
  };

  return (
    <Box
      className="product-information"
      sx={{ padding: "16px", maxWidth: "800px", margin: "auto" }}
    >
      <Typography
        sx={{ fontSize: "30px", fontWeight: "bold", marginBottom: 0 }}
        gutterBottom
      >
        {data.name_en}
      </Typography>
      <Box>
        <Typography sx={{ fontSize: "22px", color: "gray" }}>
          {selectedVariation && selectedVariation.price
            ? `${selectedVariation.price} IQD`
            : t("No variation selected")}
        </Typography>
      </Box>
      <ProductDetailReviews
        rating={Math.floor(reviewData?.average_rating)}
        totalReviews={reviewData?.count}
      />
      <div className="product-attributes-bar">
        {data.trending && (
          <div className="product-attribute">
            <span className="attribute-emoji">🔥</span>
            <span className="attribute-text">{t("Trending")}</span>
          </div>
        )}
        {data.best_selling && (
          <div className="product-attribute">
            <span className="attribute-emoji">🏆</span>
            <span className="attribute-text">{t("Best Selling")}</span>
          </div>
        )}
        {data.hot && (
          <div className="product-attribute">
            <span className="attribute-emoji">🌶️</span>
            <span className="attribute-text">{t("Hot")}</span>
          </div>
        )}
        {data.featured && (
          <div className="product-attribute">
            <span className="attribute-emoji">⭐</span>
            <span className="attribute-text">{t("Featured")}</span>
          </div>
        )}
        {data.new_arrival && (
          <div className="product-attribute">
            <span className="attribute-emoji">🆕</span>
            <span className="attribute-text">{t("New Arrival")}</span>
          </div>
        )}
      </div>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "16px",
          flexWrap: "wrap",
          mt: 2,
        }}
      >
        {/* Quantity Selector */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid black",
            borderRadius: "20px",
            padding: "0px 12px",
          }}
        >
          <IconButton
            size="small"
            onClick={() => setQuantity((prev) => Math.max(1, prev - 1))}
            sx={{
              backgroundColor: "transparent",
              color: "black",
              width: "30px",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            –
          </IconButton>

          <TextField
            value={quantity}
            onChange={(e) => {
              const newValue = e.target.value.trim();
              // Allow only numeric input
              if (/^\d*$/.test(newValue)) {
                setQuantity(Math.max(1, parseInt(newValue || "1", 10)));
              }
            }}
            variant="standard"
            inputProps={{
              style: {
                textAlign: "center", // Center the text
                width: "50px", // Adjust width as needed
                padding: "0",
              },
            }}
            sx={{
              "& .MuiInput-underline:before, & .MuiInput-underline:after": {
                borderBottom: "none", // Remove bottom border
              },
              "& .MuiInput-underline:hover:before": {
                borderBottom: "none !important", // Prevent border on hover
              },
              "& .MuiInputBase-input": {
                textAlign: "center",
                fontWeight: "bold",
              },
            }}
          />

          <IconButton
            size="small"
            onClick={() => setQuantity((prev) => prev + 1)}
            sx={{
              backgroundColor: "transparent",
              color: "black",
              width: "30px",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            +
          </IconButton>
        </Box>

        {/* Add to Cart Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddToCart}
          sx={{
            padding: "8px 24px",
            borderRadius: "20px",
            fontWeight: "bold",
            backgroundColor: "black",
            "&:hover": { backgroundColor: "gray" },
          }}
        >
          {t("ADDCART")}
        </Button>
        <IconButton>
          <FavoriteBorderIcon />
        </IconButton>
      </Box>

      <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "bold", color: "gray" }}>
          {t("Brand")} :
        </Typography>
        <Typography>{` ${data?.brand_name}`}</Typography>
      </Box>
    </Box>
  );
};

export default ProductInformation;
