import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const AddCard = ({ onClose }) => {
    const stripe = useStripe();
    const { isLoggedIn, userId } = useSelector((state) => state.authSlice);
    const elements = useElements();

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleSaveCard = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        setMessage('');

        try {
            // Call your backend to create a Setup Intent
            const response = await fetch(`${process.env.REACT_APP_BASE_URL_NEW}/payment/saveCard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });

            const data = await response.json();

            if (!data.success) {
                throw new Error(data.message || 'Failed to create Setup Intent.');
            }

            const clientSecret = data.clientSecret;

            // Confirm the Setup Intent with Stripe.js
            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: 'Cardholder Name', // Replace with actual cardholder name if available
                    },
                },
            });

            if (result.error) {
                throw new Error(result.error.message);
            }

            // Success
            toast.success('Card saved successfully!');
            onClose();
     
        } catch (error) {
       
            setMessage(`Error: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSaveCard} style={{ maxWidth: '400px', margin: '0 auto' }}>
            <h2>Save Your Card</h2>

            <div style={{ marginBottom: '20px' }}>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
            </div>

            <button
                type="submit"
                disabled={!stripe || isLoading}
                style={{
                    backgroundColor: '#5469d4',
                    color: '#fff',
                    border: 'none',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                }}
            >
                {isLoading ? 'Saving...' : 'Save Card'}
            </button>

            {message && (
                <div style={{ marginTop: '20px', color: message.startsWith('Error') ? 'red' : 'green' }}>
                    {message}
                </div>
            )}
        </form>
    );
};

export default AddCard;