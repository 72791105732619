import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoggedInCheck } from "../../../utils/helper";

// Initial cart state
const initialState = {
  items: JSON.parse(sessionStorage.getItem("cart")) || [], // Load from sessionStorage
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const {
        productId,
        size,
        color,
        variation,
        price,
        sku,
        quantityDiscount = null,
        stock,
        scale,
        isLoggedIn,
        token, // Pass user token for authentication
        navigate
      } = action.payload;
      let { quantity } = action.payload;

      const existingItemIndex = state.items.findIndex(
        (item) =>
          item.productId === productId &&
          item.size === size &&
          item.variation === variation &&
          item.sku === sku
      );

      if (existingItemIndex >= 0) {
        // Existing item logic
        const newQuantity = state.items[existingItemIndex].quantity + quantity;

        if (newQuantity > stock) {
          quantity = stock - state.items[existingItemIndex].quantity;
        }

        state.items[existingItemIndex].quantity += quantity;

        if (
          quantityDiscount &&
          state.items[existingItemIndex].quantity >= quantityDiscount.quantity
        ) {
          state.items[existingItemIndex].discountedPrice =
            (1 - quantityDiscount.discount / 100) *
            state.items[existingItemIndex].basePrice;
          state.items[existingItemIndex].appliedDiscount = quantityDiscount;
        } else {
          state.items[existingItemIndex].discountedPrice =
            state.items[existingItemIndex].basePrice;
          state.items[existingItemIndex].appliedDiscount = null;
        }
      } else {
        // New item logic
        if (quantity > stock) {
          quantity = stock;
        }

        let itemPrice = price;
        let discountedPrice = itemPrice;

        if (quantityDiscount && quantity >= quantityDiscount.quantity) {
          discountedPrice = (1 - quantityDiscount.discount / 100) * itemPrice;
        }

        const newItem = {
          ...action.payload,
          basePrice: itemPrice,
          discountedPrice,
          quantity,
          quantityDiscount,
          appliedDiscount: quantityDiscount || null,
        };

        state.items.push(newItem);
      }

      // Persist updated cart to sessionStorage
      sessionStorage.setItem("cart", JSON.stringify(state.items));

      // If logged in, make API call to update the cart on the server
      if (isLoggedIn) {
        const transformedPayload = {
          items: state.items.map((item) => ({
            product: item.productId,
            quantity: item.quantity,
            scale: item.scale,
          })),
        };

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/cart/`,
            transformedPayload, // Send the updated cart
            {
              headers: {
                Authorization: `Bearer ${token}`, // Pass Bearer token in the header
              },
            }
          )
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              console.log("Cart updated on server:", response.data);
            } else {
              console.error("Failed to update cart on server.");
            }
          })
          .catch((error) => {
            isLoggedInCheck(error, navigate);
            console.error("Error updating cart on server:", error);
          });
      }
    },

    removeItemFromCart: (state, action) => {
      const {
        productId,
        size,
        variation,
        scale = "small",
        isLoggedIn,
        token,
      } = action.payload;

      // Remove item from local state
      state.items = state.items.filter(
        (item) =>
          !(
            item.productId === productId &&
            item.size === size &&
            item.variation === variation
          )
      );

      // Persist updated cart to sessionStorage
      sessionStorage.setItem("cart", JSON.stringify(state.items));

      // If logged in, make API call to update the server cart
      if (isLoggedIn) {
        const payload = {
          product: productId,
          scale,
        };

        axios
          .post(
            `${process.env.REACT_APP_BASE_URL_NEW}/cart/remove_item/`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Pass Bearer token in the header
              },
            }
          )
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              console.log("Item removed from server:", response.data);
            } else {
              console.error("Failed to remove item from server.");
            }
          })
          .catch((error) => {
            console.error("Error removing item from server:", error);
          });
      }
    },

    updateItemQuantity: (state, action) => {
      const {
        productId,
        size,
        color,
        variation,
        quantity,
        sku,
        quantityDiscount = null,
        stock,
        isLoggedIn, // Include login status from payload
        token, // Include token from payload
      } = action.payload;

      const existingItemIndex = state.items.findIndex(
        (item) => item.productId === productId && item.color === color
      );

      if (existingItemIndex > -1) {
        // Ensure quantity does not exceed stock
        const newQuantity = stock ? Math.min(quantity, stock) : quantity;

        if (newQuantity > 0) {
          // Update the quantity of the existing item
          state.items[existingItemIndex].quantity = newQuantity;

          // Recalculate the discounted price based on the new quantity
          if (quantityDiscount && newQuantity >= quantityDiscount.quantity) {
            state.items[existingItemIndex].discountedPrice =
              (1 - quantityDiscount.discount / 100) *
              state.items[existingItemIndex].basePrice;
            state.items[existingItemIndex].appliedDiscount = quantityDiscount;
          } else {
            state.items[existingItemIndex].discountedPrice =
              state.items[existingItemIndex].basePrice;
            state.items[existingItemIndex].appliedDiscount = null;
          }
        } else {
          // If quantity is 0 or less, remove the item
          state.items.splice(existingItemIndex, 1);
        }

        // Persist updated cart to sessionStorage
        sessionStorage.setItem("cart", JSON.stringify(state.items));

        // If logged in, make the API call
        if (isLoggedIn) {
          const transformedPayload = {
            items: state.items.map((item) => ({
              product: item.productId,
              quantity: item.quantity,
              scale: item.scale,
            })),
          };
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL_NEW}/cart/`,
              transformedPayload, // Send all items (both updated and unchanged)
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                console.log("Cart updated on server:", response.data);
              } else {
                console.error("Failed to update cart on server.");
              }
            })
            .catch((error) => {
              console.error("Error updating cart on server:", error);
            });
        }
      } else {
        console.error("Item not found in cart for update.");
      }
    },

    clearCart: (state) => {
      state.items = [];
      // Persist updated cart to sessionStorage
      sessionStorage.setItem("cart", JSON.stringify(state.items));
    },
  },
});

// Export actions for use in components
export const {
  addItemToCart,
  removeItemFromCart,
  updateItemQuantity,
  clearCart,
} = cartSlice.actions;

// Export the reducer to add to the store
export default cartSlice.reducer;
