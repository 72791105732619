import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const SubcategoryApiService = createApi({
  reducerPath: "subcategoryApi", // Ensure this matches the correct reducer path
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_BASE_URL_NEW}` }), // Base URL for your API
  endpoints: (builder) => ({
    getSubcategoriesByCategoryId: builder.query({
      query: (categoryId) => `subcategory/get/?category=${categoryId}`, // Dynamically insert the category ID
    }),
  }),
});

// Export the hook to use this query in your components
export const { useGetSubcategoriesByCategoryIdQuery } = SubcategoryApiService;
