import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define the base URL for your API
const baseUrl = `${process.env.REACT_APP_BASE_URL_NEW}/`; // Base URL

export const reviewsApiService = createApi({
    reducerPath: 'reviewsApi', // Unique key for the reducer
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
            // Log headers if needed
           
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getProductReviews: builder.query({
            query: (productId) => {
                const url = `review/${productId}`;
                // Log the query URL and method
              
                return url; // Method is GET by default
            },
            keepUnusedDataFor: 0, // Optional: set the cache time
        }),
        addProductReview: builder.mutation({
            query: ({ productId, commentuser, userRating, user }) => {
                const url = `review/add/${productId}`;
                
                // Log the body being sent
                const requestBody = {
                    rating: userRating,
                    comment: commentuser,
                    user,
                };
               
        
                return {
                    url,
                    method: 'POST', // Specify the method as POST
                    body: requestBody,
                };
            },
        }),
        
    }),
});

// Export the generated hooks for the queries and mutations
export const {
    useGetProductReviewsQuery,
    useAddProductReviewMutation, // Corrected the naming to be more consistent
} = reviewsApiService;
