import { ThemeProvider } from "@emotion/react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../redux/Apis/User";
import { StandardInputTheme } from "./StandardInputTheme";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";

const SignupForm = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signup, { isSuccess, isLoading, isError, error }] = useRegisterMutation();

  // Destructure getValues from useForm
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const maxDate = dayjs().subtract(13, "years"); // User must be at least 13 years old

  const selectedLanguage = useSelector((state) => state.language);
  const isRTL = selectedLanguage === "ar" || selectedLanguage === "kr";

  useEffect(() => {
    if (isError) {
      toast.error(error.data.email[0]);
    }
    if (isSuccess) {
      toast.success("Signup Successfully");
      navigate("/email-sent");
    }
  }, [isSuccess, isError]);

  const onSubmit = (data) => {
    const values = getValues();
    const allFieldsFilled = Object.values(values).every((field) => field);

    if (!allFieldsFilled) {
      alert("error");
      toast.error("Please fill in all fields.");
      return;
    }
    const payload = {
      ...data,
      user_type: "END_USER",
    };

    signup(payload);
  };

  return (
    <ThemeProvider theme={StandardInputTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className="login-form-container shadow"
          style={{
            direction: isRTL ? "rtl" : "ltr", // Set RTL or LTR direction
            textAlign: isRTL ? "right" : "left", // Adjust text alignment
          }}
        >
          <form>
            <h2 className="loginpage_heading">{t("Create Account")}</h2>

            {/* First Name */}
            <div className="input-group">
              <Controller
                control={control}
                name="first_name"
                rules={{ required: t("First name is required") }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("First Name")}
                    variant="standard"
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                    {...field}
                    InputLabelProps={{
                      style: {
                        transformOrigin: isRTL ? "right" : "left",
                        left: isRTL ? "inherit" : 0,
                        textAlign: isRTL ? "right" : "left",
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: isRTL ? "right" : "left", // Align helper text
                        direction: isRTL ? "rtl" : "ltr",
                      },
                    }}
                  />
                )}
              />
            </div>

            {/* Last Name */}
            <div className="input-group">
              <Controller
                control={control}
                name="last_name"
                rules={{ required: t("Last name is required") }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("Last Name")}
                    variant="standard"
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                    {...field}
                    InputLabelProps={{
                      style: {
                        transformOrigin: isRTL ? "right" : "left",
                        left: isRTL ? "inherit" : 0,
                        textAlign: isRTL ? "right" : "left",
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: isRTL ? "right" : "left", // Align helper text
                        direction: isRTL ? "rtl" : "ltr",
                      },
                    }}
                  />
                )}
              />
            </div>

            {/* Email */}
            <div className="input-group">
              <Controller
                control={control}
                name="email"
                rules={{
                  required: t("Email is required"),
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: t("Invalid email address"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("Email Address")}
                    variant="standard"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    {...field}
                    InputLabelProps={{
                      style: {
                        transformOrigin: isRTL ? "right" : "left",
                        left: isRTL ? "inherit" : 0,
                        textAlign: isRTL ? "right" : "left",
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: isRTL ? "right" : "left", // Align helper text
                        direction: isRTL ? "rtl" : "ltr",
                      },
                    }}
                  />
                )}
              />
            </div>

            {/* Phone */}
            <div className="input-group">
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: t("Phone number is required"),
                  pattern: {
                    value: /^\d+$/,
                    message: "Phone number must be a number",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("Phone")}
                    variant="standard"
                    error={!!errors.phone}
                    type="number"
                    helperText={errors.phone?.message}
                    {...field}
                    InputLabelProps={{
                      style: {
                        transformOrigin: isRTL ? "right" : "left",
                        left: isRTL ? "inherit" : 0,
                        textAlign: isRTL ? "right" : "left",
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: isRTL ? "right" : "left", // Align helper text
                        direction: isRTL ? "rtl" : "ltr",
                      },
                    }}
                  />
                )}
              />
            </div>

            {/* Date of Birth with age restriction */}
            <div className="input-group">
              <Controller
                control={control}
                name="date_of_birth"
                rules={{
                  required: "Date of birth is required",
                  validate: (value) =>
                    dayjs(value).isBefore(maxDate) ||
                    "You must be at least 13 years old",
                }}
                render={({ field }) => (
                  <DatePicker
                    label={isRTL ? "" : t("Date of Birth")}
                    maxDate={maxDate}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        error={!!errors.date_of_birth}
                        helperText={errors.date_of_birth?.message}
                        {...params}
                      />
                    )}
                    onChange={(date) => field.onChange(date)}
                    value={field.value || null}
                  />
                )}
              />
            </div>

            {/* Password */}
            <div className="input-group">
              <Controller
                control={control}
                name="password"
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      style={{
                        transformOrigin: isRTL ? "right" : "left",
                        left: isRTL ? "inherit" : 0,
                        textAlign: isRTL ? "right" : "left",
                      }}
                    >
                      {t("Password")}
                    </InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </div>

            {/* Confirm Password */}
            <div className="input-group">
              <Controller
                control={control}
                name="confirm_password"
                rules={{ required: "Please confirm your password" }}
                render={({ field }) => (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      style={{
                        transformOrigin: isRTL ? "right" : "left",
                        left: isRTL ? "inherit" : 0,
                        textAlign: isRTL ? "right" : "left",
                      }}
                    >
                      {t("Confirm Password")}
                    </InputLabel>
                    <Input
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      {...field}
                    />
                  </FormControl>
                )}
              />
            </div>

            {/* Submit Button */}
            <div className="signup-text">
              <i>{t("SwedeData")}</i>
            </div>
            <hr className="my-3 mx-2" />

            <p>
              {t("DoReadyAccount")}{" "}
              <Link to="/auth/login" className="signin_link">
                {t("Login")}
              </Link>
              <div style={{ float: isRTL ? "left" : "right" }} className="my-2">
                <button
                  type="button"
                  className="btn btn-login"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("Create Account")}
                </button>
              </div>
            </p>
          </form>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default SignupForm;
