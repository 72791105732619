import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { encodeQueryData } from '../../../utils/helper'

const baseUrl = `${process.env.REACT_APP_BASE_URL_NEW}/brand/`

export const brandApiService = createApi({
    reducerPath: 'brandApi',
    baseQuery: fetchBaseQuery({ baseUrl }),

    endpoints: (builder) => ({
        getBrands: builder.query({
            query: (args) => {

                if (args?.id) {
                    return `get/${args?.id}`
                }

                let query = encodeQueryData(args)

                return `get?${query}`
            }
        }),
        getActiveBrands: builder.query({
            query: (args) => {

                return `getActiveBrands`
            }
        })
    })
})

export const {
    useGetBrandsQuery,
    useGetActiveBrandsQuery
} = brandApiService