import React, { useState, useEffect } from "react";
import {
  TextField,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  useUpdateUserAddressMutation,
  useAddUserAddressMutation,
} from "../../redux/Apis/User";

const ShippingAddressForm = ({
  initialAddress = {},
  onCancel,
  isEditMode = false,
  refetch,
  setLoading,
}) => {
  const { t } = useTranslation();
  const [address, setAddress] = useState(initialAddress || {});
  const [errors, setErrors] = useState({});
  const [updateUserAddress] = useUpdateUserAddressMutation();
  const [addUserAddress] = useAddUserAddressMutation();

  useEffect(() => {
    setAddress(initialAddress || {});
  }, [initialAddress]);

  const handleChange = (field, value) => {
    setAddress((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" })); // Clear the error when the field is updated
  };

  const validate = () => {
    const newErrors = {};

    if (!address.first_name) newErrors.first_name = t("First Name is required");
    if (!address.last_name) newErrors.last_name = t("Last Name is required");
    if (!address.phone) newErrors.phone = t("Phone is required");
    if (!address.addressLine1)
      newErrors.addressLine1 = t("Address is required");
    if (!address.city) newErrors.city = t("City is required");
    if (!address.postalCode) {
      newErrors.postalCode = t("Postal Code is required");
    } else if (address.postalCode.length !== 5) {
      newErrors.postalCode = t("Postal Code must be 5 characters");
    }
    if (!address.governorate)
      newErrors.governorate = t("Governorate is required");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleSave = async () => {
    setLoading(true);
    if (validate()) {
      try {
        if (isEditMode) {
          // Update address API call
          await updateUserAddress({
            addressId: initialAddress?.id,
            ...address,
          });
        } else {
          // Add address API call
          await addUserAddress(address);
        }
        // Refetch the data
        await refetch();
        // Close the dialog
        onCancel();
      } catch (error) {
        console.error(t("Error saving address"), error);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog open={true} onClose={onCancel}>
      <DialogTitle>
        {isEditMode ? t("Edit Address") : t("Add Address")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
              <TextField
                fullWidth
                placeholder={t("First Name")}
                variant="outlined"
                name="first_name"
                value={address.first_name || ""}
                onChange={(e) => handleChange("first_name", e.target.value)}
                error={!!errors.first_name}
                helperText={errors.first_name}
              />
              <TextField
                fullWidth
                placeholder={t("Last Name")}
                variant="outlined"
                name="last_name"
                value={address.last_name || ""}
                onChange={(e) => handleChange("last_name", e.target.value)}
                error={!!errors.last_name}
                helperText={errors.last_name}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder={t("Phone Number")}
              variant="outlined"
              name="phone"
              value={address.phone || ""}
              onChange={(e) => handleChange("phone", e.target.value)}
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder={t("Address")}
              value={address.addressLine1 || ""}
              onChange={(e) => handleChange("addressLine1", e.target.value)}
              error={!!errors.addressLine1}
              helperText={errors.addressLine1}
              fullWidth
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              placeholder={t("City")}
              value={address.city || ""}
              onChange={(e) => handleChange("city", e.target.value)}
              error={!!errors.city}
              helperText={errors.city}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              placeholder={t("Postal Code")}
              value={address.postalCode || ""}
              onChange={(e) => handleChange("postalCode", e.target.value)}
              error={!!errors.postalCode}
              helperText={errors.postalCode}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={!!errors.governorate}>
              <InputLabel id="governorate-select-label">
              Governorate - المحافظة
              </InputLabel>
              <Select
                labelId="governorate-select-label"
                id="governorate-select"
                value={address.governorate || ""}
                onChange={(e) => handleChange("governorate", e.target.value)}
                label={t("Governorate")}
              >
                <MenuItem value="Baghdad">Baghdad - بغداد</MenuItem>
                <MenuItem value="Basra">Basra - البصرة</MenuItem>
                <MenuItem value="Mosul">Mosul - الموصل</MenuItem>
                <MenuItem value="Erbil">Erbil - أربيل</MenuItem>
                <MenuItem value="Karbala">Karbala - كربلاء</MenuItem>
                <MenuItem value="Najaf">Najaf - النجف</MenuItem>
                <MenuItem value="Kirkuk">Kirkuk - كركوك</MenuItem>
                <MenuItem value="Sulaymaniyah">
                  Sulaymaniyah - السليمانية
                </MenuItem>
                <MenuItem value="Fallujah">Fallujah - الفلوجة</MenuItem>
                <MenuItem value="Ramadi">Ramadi - الرمادي</MenuItem>
                <MenuItem value="Samarra">Samarra - سامراء</MenuItem>
                <MenuItem value="Nasiriyah">Nasiriyah - الناصرية</MenuItem>
                <MenuItem value="Duhok">Duhok - دهوك</MenuItem>
                <MenuItem value="Amara">Amara (Al-Amarah) - العمارة</MenuItem>
                <MenuItem value="Tikrit">Tikrit - تكريت</MenuItem>
                <MenuItem value="Baqubah">Baqubah - بعقوبة</MenuItem>
                <MenuItem value="Zakho">Zakho - زاخو</MenuItem>
                <MenuItem value="Hilla">Hilla - الحلة</MenuItem>
              </Select>
              {errors.governorate && (
                <Box sx={{ color: "error.main", mt: 1 }}>
                  {errors.governorate}
                </Box>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="error">
          {t("Cancel")}
        </Button>
        <Button onClick={handleSave} color="primary">
          {isEditMode ? t("Save Changes") : t("Add Address")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShippingAddressForm;
