import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = `${process.env.REACT_APP_BASE_URL_NEW}/cart/`;

export const cartApiService = createApi({
    reducerPath: 'cartApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const { token } = getState().authSlice; // Destructuring 'token' from authSlice state
            
            headers.set('Authorization', `Bearer ${token}`); // Setting the Authorization header
            return headers;
        },
    }),
    endpoints: (builder) => ({
        createCart: builder.mutation({
            query: (body) => {
                // Log the body here
              
                
                return {
                    url: 'create',
                    method: 'POST',
                    body,
                };
            },
        }),
        
        getCart: builder.query({
            query: (userId) => {
                if (userId) {
                    return `get/${userId}`; // Use userId as a query parameter
                }
                return ''; // Return an empty string or handle cases where userId is not provided
            },
        }),
        deleteCart: builder.mutation({
            query: (id) => ({
                url: `delete/${id}`,
                method: 'DELETE',
            }),
        }),
        shippingStatus: builder.mutation({
            query: (body) => {
                // Log the body here
               
                
                return {
                    url: 'get-shipping-options',
                    method: 'POST',
                    body,
                };
            },
        }),
    }),
});

export const {
    useCreateCartMutation,
    useGetCartQuery,
    useDeleteCartMutation,
    useShippingStatusMutation,
} = cartApiService;
