import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Base URL for the header messages API
const baseUrl = `${process.env.REACT_APP_BASE_URL_NEW}/header-message/`;

export const headerMessageApiService = createApi({
    reducerPath: 'headerMessageApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().authSlice.token; // Adjust the path to your token in the state

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        }
    }),
    endpoints: (builder) => ({
        // Query to get all header messages
        getAllHeaderMessages: builder.query({
            query: () => 'get',
            providesTags: ['HeaderMessage'], // Tags for invalidation
        }),
        // Query to get a single header message by ID
        getHeaderMessage: builder.query({
            query: (id) => `get/${id}`,
            providesTags: (result, error, id) => [{ type: 'HeaderMessage', id }], // Tags for invalidation
        }),
        // Mutation to create or update a header message
        createOrUpdateHeaderMessage: builder.mutation({
            query: (headerMessage) => ({
                url: 'create',
                method: 'POST',
                body: headerMessage,
            }),
            invalidatesTags: ['HeaderMessage'], // Invalidate tags after creating/updating
        }),
        // Mutation to delete a header message by ID
        deleteHeaderMessage: builder.mutation({
            query: (id) => ({
                url: `delete/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'HeaderMessage', id }], // Invalidate the specific message
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useGetAllHeaderMessagesQuery,
    useGetHeaderMessageQuery,
    useCreateOrUpdateHeaderMessageMutation,
    useDeleteHeaderMessageMutation,
} = headerMessageApiService;
