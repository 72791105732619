import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import productImage from "../../shared/images/no-image.jpg";
import "./ProductCard.css";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTranslation } from "react-i18next";
import { addItemToCart } from "../../redux/Slices/Cart";
import { openCartModal } from "../../redux/Slices/CartModal";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";

const ProductCard = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(productImage);
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const token = useSelector((state) => state.authSlice.token);
  const selectedLanguage = useSelector((state) => state.language);

  useEffect(() => {
    const img = new Image();
    img.src = data?.images?.[0] || productImage;
    img.onload = () => setImageSrc(data?.images[0]); // Valid image URL
    img.onerror = () => setImageSrc(productImage); // Fallback to default image
  }, [data?.images]);

  const handleCardClick = () => {
    navigate(`/product/${data?.id}`);
  };

  const renderStars = (rating) =>
    Array.from({ length: 5 }, (_, i) =>
      i < rating ? (
        <StarIcon key={i} className="star filled" />
      ) : (
        <StarBorderIcon key={i} className="star" />
      )
    );

  const handleAddToCart = (e) => {
    e.stopPropagation();

    const productToAdd = {
      productId: data?.id,
      sku: data.sku,
      name: data.name_en,
      price: data?.price_variations[0].price,
      quantity: 1,
      imageUrl: data.images.length > 0 ? `${data.images[0]}` : productImage,
      scale: data.price_variations[0]?.scale
        ? data.price_variations[0]?.scale
        : "small",
    };

    dispatch(
      addItemToCart({
        ...productToAdd,
        isLoggedIn,
        token,
        navigate
      })
    );
    dispatch(openCartModal());
  };

  const productName =
    data[`name_${selectedLanguage}`] || data.name_en || t("UnknownProduct");

  return (
    <div className="product-component-card-2" onClick={handleCardClick}>
      <div className="product-image-section-2">
        <img
          src={imageSrc}
          alt={productName}
          style={{
            width: "100%",
            height: "300px",
            objectFit: "cover",
            minWidth: "100%",
          }}
        />
      </div>
      <div className="product-details-section-2">
        <div className="product-component-info-2">
          <h4 className="product-component-title-2">{productName}</h4>
          {/* <div className="product-component-rating-2">
            {renderStars(Number(data?.rating) || 0)}
          </div> */}
        </div>
        <div className="product-component-pricing-2">
          <p className="product-component-price-2">
            {data?.price_variations?.length > 0
              ? `${data?.price_variations[0].price} IQD`
              : null}
          </p>
        </div>
        <div className="btn-wrap-2">
          <Button
            sx={{
              textTransform: "none",
              borderRadius: "24px",
              backgroundColor: "black",
              color: "white",
              border: "1px solid transparent",
              "&:hover": {
                color: "black",
                backgroundColor: "white",
                border: "1px solid black",
              },
            }}
            className="btn-2 btn-dark-2"
            onClick={handleAddToCart}
          >
            <LocalMallOutlinedIcon className="cart-icon" /> {t("ADDCART")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
