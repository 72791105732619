import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Divider,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  DialogActions,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import // useCreateCartMutation,
// useGetCartQuery,
// useDeleteCartMutation,
// useShippingStatusMutation,
"../../redux/Apis/Cart/index";
import { useTranslation } from "react-i18next";
import ShippingAddressForm from "./ShippingAddressForm";
import OrderDetails from "./OrderDetails";
import "./CheckoutCart.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCreditCard from "./StripeCreditCard"; // Assuming you have this component
import // useCheckUserOrdersMutation,
// useCreateOrderMutation,
"../../redux/Apis/Order";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  Box,
} from "@mui/material";
import Loader from "../Loader/Loader";
import CheckoutProducts from "./CheckoutProducts";
import {
  useDeleteUserAddressMutation,
  useGetUserAddressesQuery,
  useAddUserAddressMutation,
} from "../../redux/Apis/User";
import { clearCart } from "../../redux/Slices/Cart";

const CheckoutCart = () => {
  const location = useLocation();
  const delivery_note = location.state?.delivery_note;
  const cartItems = useSelector((state) => state.cartSlice.items);
  const dispatch = useDispatch();
  const { data: addresses, isLoading, refetch } = useGetUserAddressesQuery();
  const [deleteUserAddress] = useDeleteUserAddressMutation();
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("COD");
  const { userId, isLoggedIn, user } = useSelector((state) => state.authSlice);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [currentEditAddress, setCurrentEditAddress] = useState(null);
  const [formFields, setFormFields] = useState({
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    addressLine1: "",
    city: "",
    postalCode: "",
    governorate: "",
  });
  const [addUserAddress] = useAddUserAddressMutation();
  const [saveForNextTime, setSaveForNextTime] = useState(false);
  const [isOrderCompleted, setIsOrderCompleted] = useState(false);
  const [orderResponse, setOrderResponse] = useState(null);
  const [deleteAddressId, setDeleteAddressId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const token = useSelector((state) => state.authSlice.token);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAddresses = async () => {
      setLoading(true);
      if (isLoggedIn) {
        await refetch();
      }
      setLoading(false);
    };

    fetchAddresses();
  }, [isLoggedIn, refetch]);
  const handleInputChange = (field, value) => {
    setFormFields((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const toggleNewAddress = () => {
    setIsNewAddress(true);
    setCurrentEditAddress(null);
    setEditDialogOpen(true);
  };

  const handleEditAddress = (id) => {
    const selected = addresses?.results?.find((addr) => addr.id === id);
    if (selected) {
      setCurrentEditAddress(selected);
      setIsNewAddress(false);
      setEditDialogOpen(true);
    }
  };

  const handleCancelEdit = () => {
    setEditDialogOpen(false);
  };

  const handleAddressChange = (event) => {
    const selectedId = event.target.value;
    setSelectedAddress(selectedId);
  };

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleDeleteAddress = (id) => {
    setDeleteAddressId(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteAddressId(null);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    if (!deleteAddressId) return;

    try {
      await deleteUserAddress({ addressId: deleteAddressId });
      setDeleteDialogOpen(false);
      setDeleteAddressId(null);
      await refetch(); // Refresh the addresses list
    } catch (error) {
      console.error("Error deleting address:", error);
    }
    setLoading(false);
  };

  const cancelNewAddress = () => {
    setIsNewAddress(false);
    setEditDialogOpen(false);
  };

  const commonAddressForm = () => {
    return (
      <>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            fullWidth
            placeholder={t("First Name")}
            variant="outlined"
            name="first_name"
            value={formFields.first_name}
            onChange={(e) => handleInputChange("first_name", e.target.value)}
            error={!!errors.first_name}
            helperText={errors.first_name}
          />
          <TextField
            fullWidth
            placeholder={t("Last Name")}
            variant="outlined"
            name="last_name"
            value={formFields.last_name}
            onChange={(e) => handleInputChange("last_name", e.target.value)}
            error={!!errors.last_name}
            helperText={errors.last_name}
          />
        </Box>
        <TextField
          fullWidth
          placeholder={t("Phone Number")}
          variant="outlined"
          name="phone"
          value={formFields.phone}
          onChange={(e) => handleInputChange("phone", e.target.value)}
          error={!!errors.phone}
          helperText={errors.phone}
        />
        <TextField
          fullWidth
          placeholder={t("Address")}
          variant="outlined"
          sx={{ marginTop: 0 }}
          value={formFields.addressLine1}
          onChange={(e) => handleInputChange("addressLine1", e.target.value)}
          name="addressLine1"
          error={!!errors.addressLine1}
          helperText={errors.addressLine1}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            fullWidth
            placeholder={t("City")}
            variant="outlined"
            value={formFields.city}
            name="city"
            error={!!errors.city}
            helperText={errors.city}
            onChange={(e) => handleInputChange("city", e.target.value)}
          />
          <TextField
            fullWidth
            placeholder={t("Postal Code")}
            variant="outlined"
            value={formFields.postalCode}
            onChange={(e) => handleInputChange("postalCode", e.target.value)}
            name="postalCode"
            error={!!errors.postalCode}
            helperText={errors.postalCode}
          />
        </Box>
        <FormControl fullWidth>
          <InputLabel id="governorate-select-label">
            Governorate - المحافظة
          </InputLabel>
          <Select
            labelId="governorate-select-label"
            id="governorate-select"
            value={formFields.governorate}
            name="governorate"
            lable="Governorate - المحافظة"
            onChange={(e) => handleInputChange("governorate", e.target.value)}
          >
            <MenuItem value="Baghdad">Baghdad - بغداد</MenuItem>
            <MenuItem value="Basra">Basra - البصرة</MenuItem>
            <MenuItem value="Mosul">Mosul - الموصل</MenuItem>
            <MenuItem value="Erbil">Erbil - أربيل</MenuItem>
            <MenuItem value="Karbala">Karbala - كربلاء</MenuItem>
            <MenuItem value="Najaf">Najaf - النجف</MenuItem>
            <MenuItem value="Kirkuk">Kirkuk - كركوك</MenuItem>
            <MenuItem value="Sulaymaniyah">Sulaymaniyah - السليمانية</MenuItem>
            <MenuItem value="Fallujah">Fallujah - الفلوجة</MenuItem>
            <MenuItem value="Ramadi">Ramadi - الرمادي</MenuItem>
            <MenuItem value="Samarra">Samarra - سامراء</MenuItem>
            <MenuItem value="Nasiriyah">Nasiriyah - الناصرية</MenuItem>
            <MenuItem value="Duhok">Duhok - دهوك</MenuItem>
            <MenuItem value="Amara">Amara (Al-Amarah) - العمارة</MenuItem>
            <MenuItem value="Tikrit">Tikrit - تكريت</MenuItem>
            <MenuItem value="Baqubah">Baqubah - بعقوبة</MenuItem>
            <MenuItem value="Zakho">Zakho - زاخو</MenuItem>
            <MenuItem value="Hilla">Hilla - الحلة</MenuItem>
          </Select>
          {errors.governorate && (
            <Box sx={{ color: "error.main", mt: 1 }}>{errors.governorate}</Box>
          )}
        </FormControl>
        {isLoggedIn && addresses?.results?.length === 0 && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setSaveForNextTime(!saveForNextTime)}
                name="saveForNextTime"
                color="primary"
              />
            }
            label="Save this information for next time"
          />
        )}
      </>
    );
  };

  const validate = (address) => {
    const newErrors = {};

    if (!isLoggedIn && !address.email) newErrors.email = "Email is required";
    if (!address.first_name) newErrors.first_name = "First Name is required";
    if (!address.last_name) newErrors.last_name = "Last Name is required";
    if (!address.phone) newErrors.phone = "Phone is required";
    if (!address.addressLine1) newErrors.addressLine1 = "Address is required";
    if (!address.city) newErrors.city = "City is required";
    if (!address.postalCode) {
      newErrors.postalCode = "Postal Code is required";
    } else if (address.postalCode.length !== 5) {
      newErrors.postalCode = "Postal Code must be 5 characters";
    }
    if (!address.governorate) newErrors.governorate = "Governorate is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleCompleteOrder = async () => {
    const addressValue = {
      ...formFields,
    };
    if (!isLoggedIn) {
      const isValid = validate(addressValue);
      if (!isValid) {
        return;
      }
      try {
        setLoading(true);
        const payload = {
          user: {
            email: formFields.email,
            phone: formFields.phone,
            first_name: formFields.first_name,
            last_name: formFields.last_name,
            address: formFields.addressLine1,
            city: formFields.city,
            postal_code: formFields.postalCode,
            governorate: formFields.governorate,
          },
          items: cartItems.map((item) => ({
            product_id: item.productId, // Make sure to match the API's expected property names
            quantity: item.quantity,
            scale: item.scale, // Include scale if necessary
          })),
          shipping_option: {
            method: "standard", // You can adjust this based on user selection
          },
          delivery_note: delivery_note,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL_NEW}/anonymous-order/`,
          payload
        );
        setOrderResponse({ ...payload, ...response.data });
        setIsOrderCompleted(true);
        setLoading(false);
      } catch (error) {
        console.error("Error completing anonymous order:", error);
      }
    } else {
      if (saveForNextTime) {
        const isValid = validate(addressValue);
        if (!isValid) {
          return;
        }
        try {
          setLoading(true);
          await addUserAddress(addressValue);
          await refetch();
          setLoading(false);
        } catch (error) {
          console.error("Error adding address:", error);
        }
      }

      try {
        setLoading(true);
        const selectedAddressForPayload = addresses?.results?.find(
          (addr) => addr.id === parseInt(selectedAddress, 10) // Check if the ID matches
        );

        const payload = {
          shippingAddress: saveForNextTime
            ? `${formFields.first_name},
               ${formFields.last_name},
               ${formFields.addressLine1},
               ${formFields.city},
               ${formFields.postalCode},
               ${formFields.governorate},
               ${formFields.country},
               ${formFields.phone}`.trim()
            : `${selectedAddressForPayload.first_name}, ${selectedAddressForPayload.last_name}, ${selectedAddressForPayload.addressLine1}, ${selectedAddressForPayload.city}, ${selectedAddressForPayload.postalCode}, ${selectedAddressForPayload.governorate}, ${selectedAddressForPayload.country}, ${selectedAddressForPayload.phone}`.trim(), // Use the selected address if not saving for next time
          shippingOption: "Standard Shipping",
          delivery_note: delivery_note,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL_NEW}/user-orders/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the Bearer token in the header
            },
          }
        );        
        setOrderResponse({ ...payload, ...response.data });
        setIsOrderCompleted(true);
      } catch (error) {
        console.error("Error completing user order:", error);
      }
      dispatch(clearCart());
      setLoading(false);
    }
  };  

  return (
    <>
      {loading && <Loader />}
      <div className="checkout-container">
        <div className="checkout-left">
          {isOrderCompleted ? (
            <Box
              sx={{
                width: "100%",
                maxWidth: "800px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  textTransform: "none",
                  fontSize: "24px",
                  marginBottom: 0,
                }}
              >
                Thank You, {orderResponse?.user?.first_name}{" "}
                {orderResponse?.user?.last_name}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  textTransform: "none",
                  fontSize: "20px",
                  marginBottom: 3,
                }}
              >
                {t("Your order is confirmed.")}
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  textTransform: "none",
                  fontSize: "20px",
                  marginBottom: 1,
                }}
              >
                {t("Order Details")}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: "16px" }}
              >
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {t("Contact Information :")}
                </span>{" "}
                {orderResponse?.user?.email}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "space-between" },
                }}
              >
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {t("Shipping Address :")}
                  </span>
                  <span>
                    {orderResponse?.user?.first_name}{" "}
                    {orderResponse?.user?.last_name}
                  </span>
                  <span>{orderResponse?.user?.address}</span>
                  <span>{orderResponse?.user?.city}</span>
                  <span>{orderResponse?.user?.postal_code}</span>
                  <span>{orderResponse?.user?.governorate}</span>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                    {t("Payment Method :")}
                  </span>
                  <span>COD (Cash On Delivery)</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 5,
                  alignItems: "center",
                }}
              >
                <span style={{}}>
                  Need Help? <Link to="/contact">Contact Us</Link>
                </span>
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    border: "1px solid transparent",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "white",
                      border: "1px solid black",
                    },
                  }}
                >
                  <Link to="/">{t("Continue Shopping")}</Link>
                </Button>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                maxWidth: 500,
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {/* Contact Section */}
              {!isLoggedIn && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" component="h2">
                    {t("Contact")}
                  </Typography>

                  <Typography
                    sx={{
                      textTransform: "none",
                      color: "black",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {t("Login")}
                  </Typography>
                </Box>
              )}
              {!isLoggedIn && (
                <TextField
                  fullWidth
                  placeholder={t("Email Address")}
                  variant="outlined"
                  name="email"
                  value={formFields.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              )}
              {isLoggedIn && (
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ textTransform: "none", fontSize: "24px" }}
                >
                  Hello, {user.first_name} {user.last_name}
                </Typography>
              )}
              {/* Delivery Section */}
              <Typography variant="h6" component="h2">
                {t("Delivery")}
              </Typography>
              {!isLoggedIn && commonAddressForm()}
              {isLoggedIn && (
                <FormControl component="fieldset">
                  <RadioGroup
                    value={selectedAddress}
                    onChange={handleAddressChange}
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    {addresses?.results?.map((addr) => (
                      <Box
                        key={addr.id}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid lightgray",
                          borderRadius: 1,
                          padding: 1,
                        }}
                      >
                        <FormControlLabel
                          value={addr.id}
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "black" },
                              }}
                            />
                          }
                          label={
                            <Box>
                              <Typography>{`${addr.first_name} ${addr.last_name}`}</Typography>
                              <Typography>{`${addr.phone}`}</Typography>
                              <Typography>{`${addr.addressLine1}, ${addr.city}, ${addr.postalCode}`}</Typography>
                              <Typography>{`${addr.governorate}`}</Typography>
                            </Box>
                          }
                          sx={{ marginRight: "auto" }}
                        />

                        <Button
                          size="small"
                          variant="text"
                          color="primary"
                          sx={{ textTransform: "none" }}
                          onClick={() => handleEditAddress(addr.id)}
                        >
                          Edit
                        </Button>
                        <Button
                          size="small"
                          variant="text"
                          color="error"
                          sx={{ textTransform: "none" }}
                          onClick={() => handleDeleteAddress(addr.id)}
                        >
                          Delete
                        </Button>
                      </Box>
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              {isLoggedIn &&
                addresses?.results?.length === 0 &&
                commonAddressForm()}

              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: addresses?.results?.length === 0 && 0,
                }}
              >
                {isLoggedIn && addresses?.results?.length > 0 && (
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor: "black",
                      textTransform: "none",
                      width: "auto",
                      border: "1px solid transparent", // Initial transparent border
                      margin: 0,
                      "&:hover": {
                        color: "black", // Text color changes to black on hover
                        backgroundColor: "white", // Background color changes to white on hover
                        border: "1px solid black", // Border added on hover
                      },
                      "&.Mui-disabled": {
                        color: "white", // Keep text color white when disabled
                        backgroundColor: "black", // Keep background black when disabled
                        border: "1px solid transparent", // Keep transparent border when disabled
                        opacity: 0.5, // Make the button appear dimmed
                        cursor: "not-allowed",
                      },
                    }}
                    disabled={isNewAddress}
                    onClick={toggleNewAddress} // This will now open the modal
                  >
                    Add new address
                  </Button>
                )}
              </Box>

              <Typography variant="h6" component="h2" sx={{ marginBottom: -2 }}>
                {t("Payment")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "gray", marginBottom: 0 }}
              >
                {t("All transactions are secure and encrypted")}
              </Typography>
              <FormControl
                component="fieldset"
                sx={{
                  border: 1,
                  padding: 1,
                  borderRadius: 1,
                  backgroundColor: "lightgray",
                }}
              >
                <RadioGroup
                  aria-label="payment-method"
                  name="payment-method"
                  value={paymentMethod}
                  onChange={handlePaymentChange}
                >
                  <FormControlLabel
                    value="COD"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "black",
                          },
                        }}
                      />
                    }
                    label={t("Cash on Delivery (COD)")}
                  />
                </RadioGroup>
              </FormControl>

              <Button
                onClick={() => handleCompleteOrder()}
                sx={{
                  marginTop: 2,
                  fontSize: "18px",
                  backgroundColor: "black",
                  color: "white",
                  border: "1px solid transparent",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "white",
                    border: "1px solid black",
                  },
                }}
              >
                {t("Complete Order")}
              </Button>
            </Box>
          )}
        </div>

        <div className="checkout-right">
          <CheckoutProducts products={cartItems} />
        </div>
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this address?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {editDialogOpen && (
          <ShippingAddressForm
            isEditMode={isNewAddress ? false : true}
            initialAddress={isNewAddress ? null : currentEditAddress}
            onCancel={isNewAddress ? cancelNewAddress : handleCancelEdit}
            refetch={refetch}
            setLoading={setLoading}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(CheckoutCart);
