export const encodeQueryData = (data) => {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}

export const isLoggedInCheck = (error, navigate) => {
    console.log("isLoggedInCheck", error?.response?.data?.code === "token_not_valid");
    console.log("isLoggedInCheck", error?.response?.data?.code);
    if (error?.response?.data?.code === "token_not_valid") {
        localStorage.removeItem("user");
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        navigate("/auth/login");
    }
};