import * as React from "react";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import { useNavigate } from "react-router-dom";
// import { Undo2 } from "lucide-react";

const AccountSidebar = () => {
  const nav = useNavigate();
  return (
    <div>
      <div className="profile-sidebar-items" onClick={() => nav("/my-order")}>
        <strong className="profile-head">
          <ViewStreamIcon className="me-2" /> My Orders
        </strong>
      </div>
      {/* <div className="profile-sidebar-items" onClick={() => nav("/returns")}>
        <strong className="profile-head">
          <Undo2 className="me-2" /> Returns
        </strong>
      </div> */}
      <hr className="color-light mx-2 my-0" />
      <div className="profile-sidebar-items">
        <strong className="profile-head">
          <PersonIcon className="me-2" />
          Account Settings
        </strong>
        <div className="profile-side-bar-subitems">
          <div onClick={() => nav("/account")}>Profile Information</div>
          <div onClick={() => nav("/address-details")}>Manage Address</div>
        </div>
      </div>
      <hr className="color-light mx-2 my-0" />
      {/* <div className="profile-sidebar-items">
        <strong className="profile-head">
          <PaymentIcon className="me-2" />
          Payment
        </strong>
        <div className="profile-side-bar-subitems">
          <div onClick={()=>nav('/cards')}>Saved Cards</div>
        </div>
      </div> */}
    </div>
  );
};

export default AccountSidebar;
