import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieConsent.css'; // Import the CSS file
import { useDispatch, useSelector } from 'react-redux';
import { acceptCookies, declineCookies, trackLocationConsent, trackPreferencesConsent, enhanceExperienceConsent } from '../../redux/Slices/Cookie';
import { Button, Box, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';

const CookieConsent = () => {
  const dispatch = useDispatch();

  // State to track visibility of the consent banner
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Check cookies to determine whether to show the consent banner
    const experienceCookie = Cookies.get('experienceConsent');
    const preferencesCookie = Cookies.get('preferencesConsent');

    if (!experienceCookie || !preferencesCookie) {
      setShowConsent(true);
    }
  }, []);

  const API_TOKEN = process.env.REACT_APP_IP_TOKEN; // Replace with your actual ipinfo.io token
  const SERVER_URL = `${process.env.REACT_APP_BASE_URL_NEW}/ip/create`;

  const fetchIpInfo = async () => {
    try {
      const response = await fetch(`https://ipinfo.io/json?token=${API_TOKEN}`);
      if (!response.ok) throw new Error('Failed to fetch IP information');

      const data = await response.json();
      const { country, region, city } = data;

      await fetch(SERVER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ country, region, city }),
      });


    } catch (error) {
      console.error("Error fetching IP information or sending to server:", error);
    }
  };

  const handleAcceptAll = () => {
    Cookies.set('experienceConsent', 'accepted', { expires: 7 });
    Cookies.set('preferencesConsent', 'accepted', { expires: 7 });
    Cookies.set('locationConsent', 'accepted', { expires: 7 });
    dispatch(enhanceExperienceConsent());
    dispatch(trackPreferencesConsent());
    dispatch(acceptCookies());
    fetchIpInfo();
    setShowConsent(false);
  };

  const handleAcceptNecessary = () => {
    Cookies.set('experienceConsent', 'accepted', { expires: 7 });
    Cookies.set('preferencesConsent', 'declined', { expires: 7 });
    Cookies.set('locationConsent', 'declined', { expires: 7 });
    dispatch(acceptCookies());
    dispatch(enhanceExperienceConsent());
    setShowConsent(false);
  };

  const handleRejectCookies = () => {
    Cookies.set('experienceConsent', 'declined', { expires: 7 });
    Cookies.set('preferencesConsent', 'declined', { expires: 7 });
    Cookies.set('locationConsent', 'declined', { expires: 7 });
    dispatch(declineCookies());
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div className="wrapper">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 1,
          backgroundColor: '#ffffff',
          borderRadius: 4,
        
          maxWidth: '500px',
          width: '90%',
          margin: '10px auto',
          textAlign: 'center',
          transition: 'all 0.3s ease',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            marginBottom: 2,
            fontWeight: 700,
            color: '#333',
            fontSize: { xs: '1.2rem', sm: '1.5rem' },
          }}
        >
          Manage Your Cookie Preferences
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 3,
            color: '#777',
            lineHeight: 1.6,
          }}
        >
          We use cookies to enhance your shopping experience. Select your preferences to
          continue.
        </Typography>

        {/* Accordions */}
        {[
          {
            title: 'Necessary Cookies',
            icon: <CheckCircleOutlineIcon />,
            description:
              'These cookies are essential for the website to function, enabling secure areas like your shopping cart.',
          },
          {
            title: 'Functional Cookies',
            icon: <SettingsIcon />,
            description:
              'These cookies allow us to remember your preferences, providing you with a personalized shopping experience.',
          },
          {
            title: 'Performance Cookies',
            icon: <SpeedIcon />,
            description:
              'These cookies help us analyze website performance and traffic to improve your experience.',
          },
        ].map((cookie, index) => (
          <Accordion
            key={index}
            sx={{
              backgroundColor: '#fafafa',
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ padding: 1 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >
                {cookie.icon}
                <Box component="span" sx={{ marginLeft: 1 }}>
                  {cookie.title}
                </Box>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" color="textSecondary">
                {cookie.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        {/* Action Buttons */}
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            marginTop: 3,
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAcceptAll}
            sx={{
              borderRadius: 20,
              paddingX: 2,
              paddingY: 1.2,
              fontWeight: 'bold',
              backgroundColor: '#007bff',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#0056b3',
              },
            }}
          >
            Accept All
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleAcceptNecessary}
            sx={{
              borderRadius: 20,
              paddingX: 2,
              paddingY: 1.2,
              fontWeight: 'bold',
              textTransform: 'none',
              borderColor: '#6c757d',
              color: '#6c757d',
              '&:hover': {
                borderColor: '#5a6268',
                color: '#5a6268',
              },
            }}
          >
            Accept Necessary
          </Button>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={handleRejectCookies}
            sx={{
              borderRadius: 20,
              paddingX: 2,
              paddingY: 1.2,
              fontWeight: 'bold',
              textTransform: 'none',
              borderColor: '#dc3545',
              color: '#dc3545',
              '&:hover': {
                borderColor: '#bd2130',
                color: '#bd2130',
              },
            }}
          >
            Reject Cookies
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default CookieConsent;